<template>
  <div>
    <v-chip @click="open">
      Aanpassen

      <v-icon right>
        mdi-pencil
      </v-icon>
    </v-chip>

    <v-dialog
      v-model="dialogOpen"
      width="400"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ title }} aanpassen
        </v-card-title>

        <v-card-text class="mt-4">
          <v-checkbox
            v-model="smsNotificationsEnabledValue"
            label="SMS notificaties ingeschakeld"
          />
          <v-checkbox
            v-model="retourEnabledValue"
            label="Wolk retour ingeschakeld"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="grey darken-1"
            text
            @click="dialogOpen = false"
          >
            Annuleer
          </v-btn>

          <v-btn
            :disabled="!(isChangedSms || isChangedRetour) || loading"
            :loading="loading"
            color="green darken-1"
            text
            @click="saveChanges"
          >
            Sla op
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'OrganizationEditDialog',
  props: {
    title: {
      type: String,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
    smsNotificationsEnabled: {
      type: Boolean,
      validator: (v) => [true, false].indexOf(v) !== -1,
    },
    retourEnabled: {
      type: Boolean,
      validator: (v) => [true, false].indexOf(v) !== -1,
    },
  },
  data() {
    return {
      dialogOpen: false,
      isChangedSms: false,
      isChangedRetour: false,
      loading: false,
    };
  },
  computed: {
    smsNotificationsEnabledValue: {
      get() {
        if (this.isChangedSms) {
          return !this.smsNotificationsEnabled;
        }

        return this.smsNotificationsEnabled;
      },
      set(value) {
        if (value === this.smsNotificationsEnabled) {
          this.isChangedSms = false;
          return;
        }

        this.isChangedSms = true;
      },
    },
    retourEnabledValue: {
      get() {
        if (this.isChangedRetour) {
          return !this.retourEnabled;
        }

        return this.retourEnabled;
      },
      set(value) {
        if (value === this.retourEnabled) {
          this.isChangedRetour = false;
          return;
        }

        this.isChangedRetour = true;
      },
    },
  },
  methods: {
    open() {
      this.dialogOpen = true;
    },
    saveChanges() {
      const url = `${config.VUE_APP_API_BASE_URL}/organizations/${this.organizationId}`;
      this.loading = true;

      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'patch',
        data: {
          smsNotificationsEnabled: this.smsNotificationsEnabledValue,
          retourEnabled: this.retourEnabledValue,
        },
      })
        .then(() => {
          this.loading = false;
          this.$emit('updated-organization');
          this.dialogOpen = false;

          this.$store.commit(
            'app/snackbarText',
            {
              text: `Organisatie "${this.title}" aangepast`,
              visible: true,
            },
          );
        });
    },
  },
};
</script>
