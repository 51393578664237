<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="items"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Organisaties

            <v-spacer />

            <v-text-field
              v-model="filter"
              append-icon="mdi-magnify"
              label="Zoeken"
              single-line
              hide-details
            />

            <v-spacer />
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredOrganizations"
            :items-per-page="20"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
            must-sort
            :sort-by.sync="tableSortBy"
            :sort-desc.sync="tableSortDesc"
          >
            <template #[`item.title`]="{ item }">
              <router-link
                :to="{ name: 'organizationsShow', params: { id: item._id } }"
              >
                {{ item.title }}
              </router-link>
            </template>

            <template #[`item.smsNotificationsEnabled`]="{ item }">
              {{ item.smsNotificationsEnabled ? 'Ja' : 'Nee' }}
            </template>

            <template #[`item.retourEnabled`]="{ item }">
              {{ item.retourEnabled ? 'Ja' : 'Nee' }}
            </template>

            <template #[`item.actions`]="{ item }">
              <organization-edit-dialog
                v-if="item"
                :organization-id="item._id"
                :title="item.title"
                :sms-notifications-enabled="!!item.smsNotificationsEnabled"
                :retour-enabled="!!item.retourEnabled"
                @updated-organization="reload"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import OrganizationEditDialog from '../components/organizations/EditDialog.vue';

export default {
  components: {
    OrganizationEditDialog,
  },
  data() {
    return {
      filter: '',
      headers: [
        { text: 'Naam', align: 'start', value: 'title' },
        {
          text: 'SMS notificaties mogelijk',
          align: 'start',
          value: 'smsNotificationsEnabled',
          sortable: false,
        },
        {
          text: 'Retour ingeschakeld',
          align: 'start',
          value: 'retourEnabled',
          sortable: false,
        },
        {
          text: 'Acties',
          align: 'start',
          value: 'actions',
          sortable: false,
        },
      ],
      organizations: [],
      items: [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'Organisaties',
          disabled: true,
          exact: true,
          to: { name: 'organizations' },
        },
      ],
      tableSortBy: 'title',
      tableSortDesc: false,
    };
  },
  computed: {
    filteredOrganizations() {
      if (!this.filter) {
        return this.organizations;
      }

      return this.organizations
        .filter((o) => o.title.toLowerCase().includes(this.filter.toLowerCase()));
    },
  },
  beforeMount() {
    this.loadOrganizations();
  },
  methods: {
    reload() {
      this.loadOrganizations();
    },
    loadOrganizations() {
      const url = `${config.VUE_APP_API_BASE_URL}/organizations`;

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .then((response) => {
          this.organizations = response.data.organizations;
        });
    },
    addOrganizations() {
      const url = `${config.VUE_APP_API_BASE_URL}/organizations/add`;

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .then(() => {
          this.loadOrganizations();
        });
    },
    deleteOrganization(item) {
      const url = `${config.VUE_APP_API_BASE_URL}/organizations/${item._id}`;
      console.log({ id: item._id });

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'delete',
        data: {
          _id: item._id,
        },
      })
        .then((response) => {
          console.log(response.data);

          this.loadOrganizations();
        });
    },
  },
};
</script>
