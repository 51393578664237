var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.items,"large":""}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Organisaties "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Zoeken","single-line":"","hide-details":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredOrganizations,"items-per-page":20,"footer-props":{ itemsPerPageOptions: [10, 20, 50, 200] },"must-sort":"","sort-by":_vm.tableSortBy,"sort-desc":_vm.tableSortDesc},on:{"update:sortBy":function($event){_vm.tableSortBy=$event},"update:sort-by":function($event){_vm.tableSortBy=$event},"update:sortDesc":function($event){_vm.tableSortDesc=$event},"update:sort-desc":function($event){_vm.tableSortDesc=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'organizationsShow', params: { id: item._id } }}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.smsNotificationsEnabled",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.smsNotificationsEnabled ? 'Ja' : 'Nee')+" ")]}},{key:"item.retourEnabled",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.retourEnabled ? 'Ja' : 'Nee')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item)?_c('organization-edit-dialog',{attrs:{"organization-id":item._id,"title":item.title,"sms-notifications-enabled":!!item.smsNotificationsEnabled,"retour-enabled":!!item.retourEnabled},on:{"updated-organization":_vm.reload}}):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }